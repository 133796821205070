<template>
    <div>
        Course
    </div>
</template>

<script>
export default {
    name: 'SpaceSingleMbundleHome',
    data() {
        return {
            labels: {}
        }
    },
    computed: {
        
    },
    methods: {
        
    },
    components: {
        
    }
}
</script>

<style scoped lang="scss">
    @import "@/styles/common";

    
</style>